import { LOCALE_RO } from '$i18n/i18n-constants';
import { default as i18_ro } from '$lib/data/i18n/ro.json';
import type { C9Map } from '$lib/models/object.models';
import { isStringNotEmpty } from '@rigu/js-toolkit';
import { derived, writable } from 'svelte/store';

export const locale = writable(LOCALE_RO);

const flatTranslation = <T extends C9Map<string>>(trans: T, prefix: string = ''): C9Map<string> => {
  const keyPrefix = isStringNotEmpty(prefix) ? prefix + '.' : '';
  return Object.keys(trans).reduce((result, key) => {
    const translationKey = keyPrefix + key;
    const value = trans[key];

    if (typeof value === 'string') {
      return {
        ...result,
        [translationKey]: value,
      };
    }

    return {
      ...result,
      ...flatTranslation(value, translationKey),
    };

  }, {});
};

export const translations: C9Map<C9Map<string | never>> = {
  ro: flatTranslation(i18_ro as unknown as C9Map<string | never>),
};

export const translate = (locale: string, key: string = '', vars: C9Map<string> = {}): string => {
  if (!key) {
    // throw new Error('no key provided to $t()');
    return '';
  }
  if (!locale) {
    throw new Error(`no translation for key "${key}"`);
  }

  let text = translations[locale][key] ?? '';

  Object.keys(vars).map((k) => {
    const regex = new RegExp(`{{${k}}}`, 'g');
    text = text.replace(regex, vars[k]);
  });

  return text;
};

export const t = derived(locale, ($locale) => (key: string | undefined, vars = {}): string => translate($locale, key, vars));
